import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
// import { EffectCards, Autoplay } from "swiper";
import "./styles.css";
import Navbar from "./components/Navbar";
import MobileNav from "./components/MobileNav";

import snek from "./images/pepe1.png";
import logo from "./images/logopepe.png";
import several from "./images/several.gif";
import chart from "./images/chart-2.png";
import fun1 from "./images/fun1.jpg";
import fun2 from "./images/fun2.jpg";
import fun3 from "./images/strong-snek.png";
import team1 from "./images/normal-snake.png"
import team2 from "./images/cm.png"
import team3 from "./images/admin-snek.png"
import team4 from "./images/spooder-snek.png"

const Team = () => {
  return (
    <>
      <div className="section team" id="team">
        <h1 className="title">MEET THE TEAM</h1>
        <div className="flex">
          <div className="member">
            <img src={team1} alt="" />
            <h2>Jimmy Cooks</h2>
            <h3>
              The chef leader
            </h3>
          </div>
          <div className="member">
            <img src={team2} alt="" />
            <h2>XunXin</h2>
            <h3>
              Marketing master
            </h3>
          </div>
          <div className="member">
            <img src={team3} alt="" />
            <h2>Skuzal</h2>
            <h3>
              Founder
            </h3>
          </div>
          <div className="member">
            <img src={team4} alt="" />
            <h2>Kim</h2>
            <h3>
              Designer
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

const Orcas = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    { src: fun1, alt: "Fun image 1" },
    { src: fun2, alt: "Fun image 2" },
    { src: fun3, alt: "Fun image 2" },
    { src: fun2, alt: "Fun image 1" },
    { src: fun3, alt: "Fun image 1" },
    { src: fun1, alt: "Fun image 2" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 2) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <div className="section orcas" id="orcas">
        <h1 className="title">Fairlaunch</h1>
        <div className="flex one">
          <div className="image-container">
            <img src={images[currentImageIndex].src} alt={images[currentImageIndex].alt} />
          </div>
          <div className="flexText">
            <h1>movepump Fairlaunch</h1>
            <h2>
              Buy on movepump platform, SUI blockchain with your favorite wallet. 
            </h2>
          </div>
        </div>
        <div className="flex two">
          <div className="flexText">
            <h1>Marketing</h1>
            <h2>
              The team will have 10% token supply for marketing issues, such as dexscreener verification once is available.
            </h2>
          </div>
          <div className="image-container">
            <img src={images[(currentImageIndex + 3) % images.length].src} alt={images[(currentImageIndex + 3) % images.length].alt} />
          </div>
        </div>
      </div>
    </>
  );
};

const FAQ = () => {
  const [question, setQuestion] = useState(0);
  return (
    <>
      <div className="section faq" id="faq">
        <h1 className="title">FAQ</h1>
        <div className="question">
          <div
            onClick={() => (question !== 1 ? setQuestion(1) : setQuestion(0))}
          >
            <svg
              style={question === 1 ? { transform: "rotate(-90deg)" } : null}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
            </svg>
            <h1
              style={
                question === 1
                  ? {
                    borderBottomRightRadius: "0",
                    borderBottomLeftRadius: "0"
                  }
                  : null
              }
            >
              Where can I know more about SUI SNEK?
            </h1>
          </div>
          {question === 1 ? (
            <h2>
              Twitter and Telegram
            </h2>
          ) : null}
        </div>
        <div className="question">
          <div
            onClick={() => (question !== 2 ? setQuestion(2) : setQuestion(0))}
          >
            <svg
              style={question === 2 ? { transform: "rotate(-90deg)" } : null}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
            </svg>
            <h1
              style={
                question === 2
                  ? {
                    borderBottomRightRadius: "0",
                    borderBottomLeftRadius: "0"
                  }
                  : null
              }
            >
              What is SUI SNEK?
            </h1>
          </div>
          {question === 2 ? (
            <h2>
              Your Lambo keys
            </h2>
          ) : null}
        </div>
        <div className="question">
          <div
            onClick={() => (question !== 3 ? setQuestion(3) : setQuestion(0))}
          >
            <svg
              style={question === 3 ? { transform: "rotate(-90deg)" } : null}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
            </svg>

            <h1
              style={
                question === 3
                  ? {
                    borderBottomRightRadius: "0",
                    borderBottomLeftRadius: "0"
                  }
                  : null
              }
            >
              When?
            </h1>
          </div>
          {question === 3 ? (
            <h2>
              Stealth!
            </h2>
          ) : null}
        </div>
        <div className="question">
          <div
            onClick={() => (question !== 4 ? setQuestion(4) : setQuestion(0))}
          >
            <svg
              style={question === 4 ? { transform: "rotate(-90deg)" } : null}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
            </svg>

            <h1
              style={
                question === 4
                  ? {
                    borderBottomRightRadius: "0",
                    borderBottomLeftRadius: "0"
                  }
                  : null
              }
            >
              How to buy?
            </h1>
          </div>
          {question === 4 ? (
            <h2>
              Get your SUI wallet and buy on movepump
            </h2>
          ) : null}
        </div>
        <div className="question">
          <div
            onClick={() => (question !== 5 ? setQuestion(5) : setQuestion(0))}
          >
            <svg
              style={question === 5 ? { transform: "rotate(-90deg)" } : null}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
            </svg>

            <h1
              style={
                question === 5
                  ? {
                    borderBottomRightRadius: "0",
                    borderBottomLeftRadius: "0"
                  }
                  : null
              }
            >
              Why no roadmap?
            </h1>
          </div>
          {question === 5 ? (
            <h2>
              There where we go, don't need a roadmap
            </h2>
          ) : null}
        </div>
        {/* <div className="question">
          <div
            onClick={() => (question !== 6 ? setQuestion(6) : setQuestion(0))}
          >
            <svg
              style={question === 6 ? { transform: "rotate(-90deg)" } : null}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path d="M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z" />
            </svg>

            <h1
              style={
                question === 6
                  ? {
                    borderBottomRightRadius: "0",
                    borderBottomLeftRadius: "0"
                  }
                  : null
              }
            >
              Will there be a BPS Dao?
            </h1>
          </div>
          {question === 6 ? (
            <h2>
              Yes, there will be a BPS DAO. Paid alpha hunters for the DAO
              will be chosen from our community after a trial period (more
              info after mint).
            </h2>
          ) : null}
        </div> */}
      </div>
    </>
  );
};

const Header = () => {
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const circles = document.querySelector('.circles');
      if (circles) {
        circles.style.transform = `translateY(${scrollY * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="header">
        <h1>SUI SNEK</h1>
        <h2>The sui tadpole </h2>
        <div className="circles">
          <div className="circle1" />
          <div className="circle2" />
          <div className="circle3" />
          <div className="circle4" />
          <div className="circle5" />
          <div className="circle6" />
        </div>
        <div className="sun" />
        <div className="wavesContainer">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlinkHref="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
        <div className="floating">
          <img
            src={snek}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

const About = () => {
  return (
    <>
      <div className="section about" id="about">
        <h1 className="title">What the fuk</h1>
        <div className="flex">
          <div>
            <h2>
              SUI SNEK, the snake version of the iconic Pepe the Frog, slithered into the meme universe with a unique charm and mischievous spirit. SUI SNEK aims to unite fans of internet culture, memes, and humor in a community where laughter and creativity flow freely. By embodying the playful essence of Pepe, SUI SNEK has become a symbol of adaptability and wit, seamlessly transitioning from land to water, and now into the digital realm.

            </h2>
            <h2>
            The SUI SNEK community is designed to welcome everyone, from those new to meme culture to seasoned meme lords. Whether you're just discovering the joy of internet humor or you've been creating viral content for years, SUI SNEK offers a space where you can contribute, learn, and share in the fun. The community thrives on collaboration, encouraging members to create and share their own SUI SNEK-themed content, whether it's memes, artwork, or stories.

            </h2>
          </div>
          <img className="about-img" src={logo} alt="" />
        </div>
      </div>
    </>
  );
};

const Mission = () => {
  return (
    <>
      <div className="mission" id="mission">
        <div className="flex">
          <div className="left">
            <p>
              Bro <span className="blue">our roadmap is</span>{" "}
              To the Moon!
            </p>
          </div>
          <div className="right">
           <img src={chart} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};


const Footer = () => {
  return (
    <>
      <div className="footer" id="footer">
        <div className="flex">
          <h1>SUI SNEK</h1>
          <h3
            style={{ cursor: "pointer" }}
            // onClick={() => {
            //   window.open("mailto:info@bluepond.io");
            // }}
          >
            2024 all rights reserved
          </h3>
        </div>
        <div className="flex">
          <h2>Site Map</h2>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-20}
            duration={500}
          >
            <h3>About</h3>
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-20}
            duration={500}
          >
            <h3>Fairlaunch</h3>
          </Link>
          {/* <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-20}
            duration={500}
          >
            <h3>Services</h3>
          </Link> */}
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-20}
            duration={500}
          >
            <h3>Team</h3>
          </Link>
          <Link
            activeClass="active"
            to="about"
            spy={true}
            smooth={true}
            offset={-20}
            duration={500}
          >
            <h3>FAQ</h3>
          </Link>
        </div>
        <div className="flex">
          <h2>Socials</h2>
          <a href="https://t.me/SUISNEK" target="_blank" rel="noreferrer">
            <h3>Telegram</h3>
          </a>
          <a href="https://x.com/SNEKSUI" target="_blank" rel="noreferrer">
            <h3>Twitter</h3>
          </a>
        </div>
      </div>
    </>
  );
};


export default function App() {


  /*const swiperRef = useRef(null);

  const toSlide = (num) => {
    swiperRef.current?.swiper.slideTo(3);
  };

  useEffect(() => {
    toSlide()
  }, [])*/
 

  // const Services = () => {
  //   const [selected, setSelected] = useState([]);
  //   const [name, setName] = useState("");
  //   const [email, setEmail] = useState("");
  //   const [project, setProject] = useState("");
  //   const [description, setDescription] = useState("");
  //   const [extra, setExtra] = useState("");

  //   useEffect(() => {
  //     console.log(selected);
  //   }, [selected]);

  //   const handleSelected = (item) => {
  //     if (item === "The WHOLE Package") {
  //       if (selected[0] === "The WHOLE Package") {
  //         setSelected([]);
  //       } else {
  //         setSelected([item]);
  //       }
  //     } else if (
  //       selected[0] === "The WHOLE Package" &&
  //       item !== "The WHOLE Package"
  //     ) {
  //       setSelected([item]);
  //     } else if (selected.includes(item)) {
  //       var filtered = selected.filter(function (value, index, arr) {
  //         return value != item;
  //       });
  //       setSelected(filtered);
  //     } else {
  //       setSelected([...selected, item]);
  //     }
  //   };

  //   const selectedBox = (item) => {
  //     if (selected.length > 0) {
  //       if (selected.includes(item)) {
  //         return { opacity: "1" };
  //       } else {
  //         return { opacity: "0.6" };
  //       }
  //     }
  //   };
  //   const selectedButton = (item) => {
  //     if (selected.includes(item)) {
  //       return (
  //         <button
  //           style={{ backgroundColor: "#fff", color: "#000" }}
  //           onClick={() => handleSelected(item)}
  //         >
  //           Selected
  //         </button>
  //       );
  //     } else {
  //       return (
  //         <button
  //           style={{ backgroundColor: "transparent" }}
  //           onClick={() => handleSelected(item)}
  //         >
  //           Choose Plan
  //         </button>
  //       );
  //     }
  //   };

  //   return (
  //     <>
  //       <div className="section services" id="services">
  //         <h1 className="title">SERVICES</h1>
  //         <div className="flex">
  //           <div className="box" style={selectedBox("NFT Website")}>
  //             <h1>NFT Website</h1>
  //             <h2>
  //               Get a fully customized NFT landing page from our experienced dev
  //               team! You can be in contact with them through the whole
  //               experience to make sure it is done exactly to your needs!
  //             </h2>
  //             {selectedButton("NFT Website")}
  //           </div>
  //           <div className="box" style={selectedBox("NFT Design")}>
  //             <h1>NFT Design</h1>
  //             <h2>
  //               We have a very broad selection of artists by our side, and will
  //               pick the best one that fits your project’s needs! They will
  //               design all traits/assets, come up with a rarity chart for each
  //               trait and even plan the utility for your NFTs!
  //             </h2>
  //             {selectedButton("NFT Design")}
  //           </div>
  //           <div className="box" style={selectedBox("NFT Planning")}>
  //             <h1>NFT Planning</h1>
  //             <h2>
  //               We will plan out the tokenomics, roadmap, mint distribution,
  //               mint price, # of NFTs, total profit for you, and a nice
  //               workspace setup using Trello!
  //             </h2>
  //             {selectedButton("NFT Planning")}
  //           </div>
  //           <div className="box" style={selectedBox("NFT Deployment")}>
  //             <h1>NFT Deployment</h1>
  //             <h2>
  //               Once all the individual art pieces are done, how do I turn them
  //               into an NFT? Don’t worry, our dev team knows the ropes and can
  //               help you out! Using a complex program, we will uniquely combine
  //               and generate ALL art pieces needed for your project and deploy
  //               them as NFT’s to Solana/ETH or any blockchain of choice!
  //             </h2>
  //             {selectedButton("NFT Deployment")}
  //           </div>
  //           <div className="box" style={selectedBox("Discord Setup")}>
  //             <h1>Discord Setup</h1>
  //             <h2>
  //               Get a fully customized discord setup with unique channels,
  //               private admin servers, and all the necessary bots to prevent
  //               scamming and hacking within your discord!
  //             </h2>
  //             {selectedButton("Discord Setup")}
  //           </div>
  //           <div className="box" style={selectedBox("The WHOLE Package")}>
  //             <h1>The WHOLE Package</h1>
  //             <h2>
  //               Having trouble deciding what is all necessary to do for your
  //               project? The truth is, ALL of these are necessary for a
  //               successful project, and we will do ALL of it for you in one
  //               shot!
  //             </h2>
  //             {selectedButton("The WHOLE Package")}
  //           </div>
  //         </div>
  //         {selected.length != 0 ? (
  //           <>
  //             <h2 id="selectMultiple">(You can select multiple plans)</h2>
  //             <form className="servicesForm" onSubmit={sendEmail}>
  //               <input
  //                 type="text"
  //                 placeholder="Contact name"
  //                 value={name}
  //                 onChange={(e) => setName(e.target.value)}
  //                 name="name"
  //               />
  //               <input
  //                 type="text"
  //                 placeholder="Contact email"
  //                 value={email}
  //                 onChange={(e) => setEmail(e.target.value)}
  //                 name="email"
  //               />
  //               <input
  //                 type="text"
  //                 placeholder="Project name"
  //                 value={project}
  //                 onChange={(e) => setProject(e.target.value)}
  //                 name="project"
  //               />
  //               <textarea
  //                 name="description"
  //                 id="messageInput"
  //                 type="textarea"
  //                 placeholder="Project description"
  //                 value={description}
  //                 onChange={(e) => setDescription(e.target.value)}
  //               ></textarea>
  //               <textarea
  //                 name="extra"
  //                 id="messageInput"
  //                 type="textarea"
  //                 placeholder="Special requests/extra info"
  //                 value={extra}
  //                 onChange={(e) => setExtra(e.target.value)}
  //               ></textarea>
  //               <input
  //                 name="plans"
  //                 id="plans"
  //                 type="text"
  //                 placeholder="Plans"
  //                 value={selected.join(", ")}
  //               />
  //               <input id="submit" type="submit" />
  //             </form>
  //           </>
  //         ) : null}
  //       </div>
  //     </>
  //   );
  // };

  return (
    <div className="App">
      <Navbar />
      <MobileNav />
      <Header />
      <div className="mainBody">
        <img
          className="fish"
          src={several}
          alt=""
        />
        <img
          className="fish"
          src={several}
          alt=""
        />
        <img
          className="fish"
          src={several}
          alt=""
        />
        <img
          className="fish"
          src={several}
          alt=""
        />
        <img
          className="fish"
          src={several}
          alt=""
        />
        <About />
        <Mission />
        <Orcas />
        {/* <Services /> */}
        <Team />
        <FAQ />
        <Footer />
      </div>
    </div>
  );
}
