import { Link, animateScroll as scroll } from "react-scroll";
import React, { useState } from "react";
import Socials from "./Socials";
import '../styles.css';

const MobileNav = () => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <div className="mobileNav">
        <div
          className="inner"
          data-aos="fade"
          data-aos-duration="1500"
          data-aos-delay="00"
        >
          <div
            className="logo"
            onClick={() => {
              scroll.scrollToTop();
            }}
          >
            <h1>BLUE PEPE</h1>
          </div>
          <div
            className={`buttonWrapper ${dropdown ? "open" : "closed"}`}
            onClick={() => setDropdown(!dropdown)}
          >
            <div className="hamburger"></div>
          </div>
        </div>
      </div>
      <div className="mainDropdown">
        {dropdown ? (
          <div className="drop">
            <div className="sidebar">
              <div className="innerSidebar">
                <Link
                  activeClass="active"
                  to="about"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                >
                  <button onClick={() => setDropdown(false)}>
                    Our Mission
                  </button>
                </Link>
                <Link
                  activeClass="active"
                  to="orcas"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                >
                  <button onClick={() => setDropdown(false)}>GEN ONE</button>
                </Link>
                <Link
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                >
                  <button onClick={() => setDropdown(false)}>Services</button>
                </Link>
                <Link
                  activeClass="active"
                  to="team"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                >
                  <button onClick={() => setDropdown(false)}>Team</button>
                </Link>
                <Link
                  activeClass="active"
                  to="faq"
                  spy={true}
                  smooth={true}
                  offset={-60}
                  duration={500}
                >
                  <button onClick={() => setDropdown(false)}>FAQ</button>
                </Link>
              </div>
              <Socials />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default MobileNav;
